const contains = (selector, text) => {
  const elements = document.querySelectorAll(selector)
  return Array.prototype.filter.call(elements, element => RegExp(text).test(element.textContent))
}

const createElementFromHTML = (htmlString) => {
  const div = document.createElement('div')
  div.innerHTML = htmlString.trim()

  return div.firstChild
}

const onMediaChange = fn => fn

const mql = window.matchMedia('(max-width: 50rem)')
let isMobile = mql.matches
mql.addEventListener('change', (e) => {
  isMobile = e.matches
  onMediaChange()
})

const onRedocLoaded = (spec, scrollTop) => {
  const imgs = document.images
  const len = imgs.length
  let counter = 0

  const imageCounter = () => {
    counter += 1
    if (counter === len && scrollTop !== undefined) {
      document.documentElement.scrollTop = scrollTop
      // console.log('All images loaded!')
    }
  }

  Array.from(imgs).forEach((img) => {
    if (img.complete) {
      imageCounter()
    } else {
      img.addEventListener('load', imageCounter, false)
    }
  })

  const redocNode = document.querySelector('#redoc')
  if (redocNode) {
    // // Debug for scrolling
    // redocNode.insertBefore(createElementFromHTML('<div id="fixed"></div>'), redocNode.firstChild)
    // const fixedNode = document.querySelector('#fixed')
    // fixedNode.style = 'position: sticky; top: 0; width: 200px; padding: 5px 15px; background-color: #eee; font-size: 14px; z-index: 99;'
    // document.addEventListener('scroll', () => {
    //   const currentScrollTop = document.documentElement.scrollTop
    //   console.log('currentScrollTop', currentScrollTop)
    //   document.querySelector('#fixed').textContent = `currentScrollTop: ${currentScrollTop}`
    // })

    /**
    * Replace text '/{callbackURL_...}' with '{callbackUrl} in endpoint box'
    */
    const callbackUrlTextReg = /\/{callbackURL_/

    const callbackUrlNode = contains('div, span', callbackUrlTextReg).filter((element) => {
      const text = Array.prototype.filter
        .call(element.childNodes, child => child.nodeType === Node.TEXT_NODE)
        .map(child => child.textContent)
        .join('')
      return text.match(callbackUrlTextReg)
    })
    callbackUrlNode.forEach(el => el.textContent = '{callbackURL}')

    /**
    * Replace text 'application/json' with 'application/x-www-form-urlencoded' in content type box / next to REQUEST BODY SCHEMA
    */
    const jsonTextReg = /^application\/json$/

    const changeApplicationJsonText = () => {
      contains('div, span', jsonTextReg).forEach(el => el.textContent = 'application/x-www-form-urlencoded')
    }
    changeApplicationJsonText()

    /**
    * When clicking on a lang tab, trigger click on other same lang tabs
    */
    redocNode.querySelectorAll('.react-tabs__tab').forEach((tab) => {
      tab.addEventListener('click', (e) => {
        const clickedLang = tab.textContent

        if (e.isTrusted) { // Check if click is triggered by real user. If triggered by script, e.isTrusted = false
          const currentScrollTop = document.documentElement.scrollTop

          contains('.react-tabs__tab', clickedLang).forEach((el) => { if (el !== e.target) el.click() })

          e.target.click() // click target again to make page scroll to target instead of the last el in line#93
          document.documentElement.scrollTop = currentScrollTop

          if (clickedLang === 'Payload') changeApplicationJsonText()
        }
      })
    })

    /**
    * Append custom menu icon button to overlap original one
    */
    const closeIcon = `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" width="20" height="20">
        <g>
          <line x2="56" y2="56" y1="3.5" x1="3.5" stroke="currentColor" stroke-width="8"/>
          <line x2="56" y2="4" y1="56" x1="4" stroke="currentColor" stroke-width="8"/>
        </g>
      </svg>
    `

    const burgerIcon = `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" width="20" height="20">
        <g>
          <line x2="60" y2="7.5" y1="7.5" x1="-0.5" stroke-width="8" stroke="currentColor"/>
          <line x2="60" y2="30" y1="30" x1="0" stroke-width="8" stroke="currentColor"/>
          <line x2="60" y2="52" y1="52" x1="-0.5" stroke-width="8" stroke="currentColor"/>
        </g>
      </svg>
    `

    const menuContent = redocNode.querySelector('.menu-content')
    const menuButtonNode = menuContent.nextElementSibling
    const menuButtonClass = menuButtonNode.getAttribute('class')
    const menuIconWrapperClass = menuButtonNode.children[0].getAttribute('class')
    const menuButtonSelector = menuButtonClass.split(' ').map(item => `.${item}`).join('')

    redocNode.querySelector('.redoc-wrap').appendChild(createElementFromHTML(`
      <div class="custom-menu-button ${menuButtonClass}" style="user-select: auto box-shadow: none">
        <div class="custom-menu-icon-wrapper ${menuIconWrapperClass}" style="user-select: auto">
          ${burgerIcon}
        </div>
      </div>
    `))

    redocNode.querySelector('.custom-menu-button').addEventListener('click', () => {
      redocNode.querySelector(menuButtonSelector).click()
      if (menuContent.getAttribute('open') !== null) {
        menuContent.setAttribute('custom-open', '')
      } else {
        menuContent.removeAttribute('custom-open')
      }
    })

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'custom-open') {
          const isMenuHide = menuContent.getAttribute('custom-open') === null

          const menuIconWrapper = redocNode.querySelector('.custom-menu-icon-wrapper')
          menuIconWrapper.innerHTML = isMenuHide ? burgerIcon : closeIcon

          if (isMobile) menuContent.style.display = isMenuHide ? 'none' : 'flex'
        }
      })
    })

    const observerConfig = {
      attributes: true,
      attributeFilter: ['open', 'custom-open'],
    }

    observer.observe(menuContent, observerConfig)

    onMediaChange(() => {
      if (isMobile) {
        const isMenuHide = menuContent.getAttribute('custom-open') === null
        menuContent.style.display = isMenuHide ? 'none' : 'flex'
      }
    })

    /**
    * Expand the nested json content on request / response examples
    */
    // Need to use setTimeout to take effect due to unknown reason
    setTimeout(() => redocNode.querySelectorAll('div.hoverable.collapsed button.collapser').forEach(el => el.click()), 100)

    /**
    * Remove (field_XXX) text next to schema type
    */
    const clickNestedFieldAndHideCompName = (tbodyNode) => {
      tbodyNode.querySelectorAll('tr').forEach((trEl) => {
        const trButton = trEl.querySelector('button')
        if (trButton) trButton.click()

        const spansInTr = trEl.querySelectorAll('span')
        spansInTr.forEach((el) => {
          if (RegExp(/\(field_/).test(el.textContent)) {
            el.parentNode.removeChild(el)
          }
        })
      })

      const tbodyObserver = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          const nextTrNodes = mutation.addedNodes
          if (nextTrNodes.length) {
            nextTrNodes.forEach((nextTrNode) => {
              if (nextTrNode.nodeName === 'TR') {
                clickNestedFieldAndHideCompName(nextTrNode.querySelector('tbody'))
              }
            })
          }
        })
      })

      tbodyObserver.observe(tbodyNode, {
        childList: true,
        subtree: true,
      })
    }

    redocNode.querySelectorAll('tbody').forEach(el => clickNestedFieldAndHideCompName(el))

    /**
    * Remove "API docs by Redocly" text with link next to menu
    */
    const redoclyLink = redocNode.querySelector('ul[role=menu] + div')
    redoclyLink.remove()

    /**
    * Add menu sub items of tag(Appendix) on sidebar and detect scroll position
    *
    * Sidebar: ul[role=menu] > li[data-item-id=tag/appendix](liTarget) > label[role="menuitem"] > span[8. Appendix 附录], svg
    * Sidebar: ul[role=menu] > li[data-item-id=tag/appendix](liTarget) > ul(subUl) > li[data-item-id=tag/appendix/8.1.-Frequently-Asked-Questions] > label[role="menuitem"]
    * Content: div#tag/appendix > div(1) > div > h1[8. Appendix 附录]
    * Content: div#tag/appendix > div(2) > div > h2#tag/appendix/8.1.-Frequently-Asked-Questions[8.1. Frequently Asked Questions 常见问题]
    */
    const liModel = redocNode.querySelector('ul[role=menu] li ul').parentNode
    const svgModel = liModel.querySelector('label > svg')
    const subUlModel = liModel.querySelector('ul')
    const subLiModel = subUlModel.querySelector('li')

    const tagName = 'appendix'
    const appendixTag = spec.tags.find(tag => tag.name === tagName)

    if (appendixTag) {
      const tagContent = redocNode.querySelector(`#tag\\/${tagName}`)
      const liTarget = redocNode.querySelector(`li[data-item-id=tag\\/${tagName}]`) // li[data-item-id="tag/appendix"]
      const liLabel = liTarget.querySelector('label')
      let liSvg

      const svgModelClone = svgModel.cloneNode(true)
      svgModelClone.style.transform = 'rotateZ(-90deg)' // >
      liLabel.appendChild(svgModelClone)
      liTarget.appendChild(subUlModel.cloneNode(true))

      const subUl = liTarget.querySelector('ul')
      subUl.innerHTML = ''

      const h2Model = redocNode.querySelector('h2')
      const h2ModelClass = h2Model.getAttribute('class')
      const h2ModelAnchor = h2Model.querySelector('a')

      const tagContentH2s = tagContent.querySelectorAll('h2')

      tagContentH2s.forEach((h2, i) => {
        const h2Text = h2.textContent
        const h2Id = `tag/${tagName}/${h2Text.replace(/[\u4e00-\u9fa5]/g, '').trim().replace(/&/g, 'and').replace(/ /g, '-')}`
        const escapedH2Id = h2Id.replace(/[/.*+?^${}()|[\]\\]/g, '\\$&')

        h2.setAttribute('id', h2Id)
        h2.classList.add(...h2ModelClass.split(' ').concat(['tag-h2']))

        const h2ModelAnchorClone = h2ModelAnchor.cloneNode(true)
        h2ModelAnchorClone.setAttribute('href', `#${h2Id}`)
        h2ModelAnchorClone.setAttribute('aria-label', `#${h2Id}`)
        h2.insertBefore(h2ModelAnchorClone, h2.firstChild)

        const subLiClone = subLiModel.cloneNode(true)
        subLiClone.setAttribute('data-item-id', h2Id)
        subUl.appendChild(subLiClone)
        subUl.querySelectorAll('span')[i].setAttribute('title', h2Id)
        subUl.querySelectorAll('span')[i].textContent = h2Text

        const subLi = redocNode.querySelector(`li[data-item-id=${escapedH2Id}]`)
        const subLiLabel = subLi.querySelector('label')
        liSvg = liLabel.querySelector('svg')

        document.addEventListener('scroll', () => {
          const h2offsetTop = h2.offsetTop
          const tagContentOffsetTop = tagContent.offsetTop
          const currentScrollTop = document.documentElement.scrollTop
          let nextH2offsetTop
          if (tagContentH2s[i + 1]) {
            nextH2offsetTop = parseInt(tagContentH2s[i + 1].offsetTop, 10)
          }

          // // Debug for scrolling
          // h2.childNodes[1].nodeValue = `${h2Text} ${h2offsetTop}`

          const windowHeight = window.innerHeight
          const tagContentOffsetBottom = tagContentOffsetTop + tagContent.offsetHeight
          const handleSidebarScrollIntoH2Section = (condition) => {
            if (condition) {
              if (tagContentOffsetBottom - windowHeight >= nextH2offsetTop) {
                window.history.replaceState(null, null, `#${h2Id}`)
                window.dispatchEvent(new Event('hashChanged'))
              }
            } else {
              // eslint-disable-next-line no-lonely-if
              if (!i && (currentScrollTop >= tagContentOffsetTop) && (currentScrollTop < h2offsetTop)) {
                window.history.replaceState(null, null, `#tag/${tagName}`)
                window.dispatchEvent(new Event('hashChanged'))
              }
            }
          }

          handleSidebarScrollIntoH2Section(
            nextH2offsetTop
              ? h2offsetTop <= currentScrollTop && currentScrollTop < nextH2offsetTop
              : h2offsetTop <= currentScrollTop,
          )

          if (!i) {
            if (tagContentOffsetTop <= currentScrollTop) {
              subUl.style.display = 'block'
              liSvg.style.transform = 'rotateZ(0deg)' // ∨
              if (currentScrollTop < h2offsetTop) {
                liLabel.classList.add('menu-active')
              }
            } else {
              subUl.style.display = 'none'
              liSvg.style.transform = 'rotateZ(-90deg)' // >
            }
          }
        })

        const { hash } = window.location
        const hashArr = hash.split('/') // ex: ['#tag', 'appendix', '8.1.-Frequently-Asked-Questions']

        // Check if the current url hash is in #tag/appendix, if so, expand the subUl
        if (hashArr[1] === tagName) {
          subUl.style.display = 'block'
          liSvg.style.transform = 'rotateZ(0deg)' // ∨

          // Check if the current url hash is in #tag/appendix/8.x.-xxxx, if so, set .submenu-active to subLiLabel
          if (hashArr[2]) {
            // Workaround for the issue below:
            // 1. go to http://localhost:5050/api-doc/multi-wallet/PRD#tag/appendix/8.11.-Location-ID-for-HORSEBOOK--Location-ID
            // 2. redoc would automatically trim the invalid hash (#tag/appendix/8.11.-Location-ID-for-HORSEBOOK--Location-ID) to #tag/appendix
            // 3. use longer time (1500ms) since this issue occurs after loading the page
            const timer = setTimeout(() => {
              window.history.replaceState(null, null, hash)
              clearTimeout(timer)
            }, 1500)

            const escapedId = hash.replace(/([/.])/g, '\\$1').replace(/^#/, '')
            if (escapedId === escapedH2Id) {
              document.documentElement.scrollTop = h2.offsetTop
              subLiLabel.classList.add('submenu-active')
            }

            liLabel.classList.remove('active')
            liLabel.classList.add('menu-inactive')
          } else {
            liLabel.classList.add('menu-active')
          }
        }
      })

      // // Workaround for the issue that currentScrollTop is 1 pixel less than h2offsetTop,
      // // which causing the url is in the preceding h2 section after clicking h2 anchor
      // tagContent.querySelectorAll('h2 a').forEach((el) => {
      //   el.addEventListener('click', () => {
      //     const h2Id = el.parentNode.getAttribute('id')
      //     const escapedH2Id = h2Id.replace(/([/.])/g, '\\$1')
      //     setTimeout(() => {
      //       const currentScrollTop = document.documentElement.scrollTop
      //       const h2offsetTop = redocNode.querySelector(`#${escapedH2Id}`).offsetTop
      //       const { hash } = window.location

      //       if (currentScrollTop !== h2offsetTop) {
      //         document.documentElement.scrollTop = h2offsetTop

      //       // Workaround for this issue:
      //       // 1. scroll to #operation/Give
      //       // 2. click 8.1. anchor
      //       // 3. the url hash is #tag/appendix which should be #tag/appendix/8.1.-Frequently-Asked-Questions
      //       } else if (hash !== `#${h2Id}` && hash.includes(tagName)) {
      //         window.location.hash = `#${h2Id}`
      //       }
      //     }, 100)
      //   })
      // })

      tagContent.querySelectorAll('h2 a').forEach((el) => {
        el.addEventListener('click', () => {
          const h2Id = el.parentNode.getAttribute('id')
          const { hash } = window.location

          // Workaround for the issue below:
          // 1. scroll into #operation/Give
          // 2. click 8.1. anchor
          // 3. redoc would automatically trim the invalid hash (#tag/appendix/8.1.-Frequently-Asked-Questions) to #tag/appendix
          setTimeout(() => {
            if (hash !== `#${h2Id}`) {
              window.history.replaceState(null, null, `#${h2Id}`)
            }
          }, 100)
        })
      })

      // When clicking 1st layer of <li> (liTarget) on sidebar, show or hide the subUl
      liTarget.addEventListener('click', () => {
        // console.log('1. click liTarget')
        window.location.hash = `#tag/${tagName}`

        const isSubUlHide = subUl.style.display === 'none'
        const isLiLabelActive = Array.from(liLabel.classList).some(i => i === 'menu-active')

        if (isSubUlHide || !isLiLabelActive) {
          subUl.style.display = 'block'
          liLabel.classList.remove('menu-inactive')
          liLabel.classList.add('menu-active')
          liSvg.style.transform = 'rotateZ(0deg)' // ∨
        } else {
          subUl.style.display = 'none'
          liLabel.classList.remove('menu-active')
          liLabel.classList.add('menu-inactive')
          liSvg.style.transform = 'rotateZ(-90deg)' // >
        }

        // Open or close menu content for mobile view
        if (isSubUlHide) {
          menuContent.setAttribute('custom-open', '')
          menuContent.setAttribute('open', '') // not work
        } else {
          menuContent.removeAttribute('custom-open')
          menuContent.removeAttribute('open')
        }
      })

      // Handle clicking 2nd layer of <li> (liTarget's subLis) on sidebar
      const subLis = liTarget.querySelectorAll('ul li')
      subLis.forEach((subLiEl) => {
        subLiEl.addEventListener('click', (e) => {
          e.stopPropagation()

          const id = subLiEl.getAttribute('data-item-id')
          const escapedId = subLiEl.getAttribute('data-item-id').replace(/([/.])/g, '\\$1')

          window.location.hash = `#${id}`
          window.dispatchEvent(new Event('hashChanged'))

          // Workaround for the issue that currentScrollTop is 1 pixel less than h2offsetTop,
          // which causing the url is in the previous h2 section after clicking h2 anchor
          const currentScrollTop = document.documentElement.scrollTop
          const h2offsetTop = redocNode.querySelector(`#${escapedId}`).offsetTop
          if (currentScrollTop !== h2offsetTop) {
            setTimeout(() => {
              // console.log('Workaround for the issue that currentScrollTop is 1 pixel less than h2offsetTop')
              document.documentElement.scrollTop = h2offsetTop
            }, 100)
          }

          // Close menu content for mobile view
          menuContent.removeAttribute('custom-open')
          redocNode.querySelector(menuButtonSelector).click()
        })
      })

      window.addEventListener('hashChanged', () => {
        const { hash } = window.location
        const hashArr = hash.split('/') // ex: ['#tag', 'appendix', '8.1.-Frequently-Asked-Questions']
        if (hashArr[2]) {
          liLabel.classList.remove('menu-active')
          liLabel.classList.add('menu-inactive')

          subLis.forEach((subLiEl) => {
            const id = subLiEl.getAttribute('data-item-id')
            if (`#${id}` === hash) {
              subLiEl.querySelector('label').classList.add('submenu-active')
            } else {
              subLiEl.querySelector('label').classList.remove('submenu-active')
            }
          })
        } else {
          liLabel.classList.remove('menu-inactive')
          liLabel.classList.add('menu-active')
          subLis.forEach(subLiEl => subLiEl.querySelector('label').classList.remove('submenu-active'))
        }
      })

      // Handle clicking other 1st layer of <li> item (liTarget's siblings) on sidebar
      liTarget.parentNode.childNodes.forEach((li) => {
        if (li.getAttribute('data-item-id') !== `tag/${tagName}`) {
          li.addEventListener('click', () => {
            subUl.style.display = 'none'
            liSvg.style.transform = 'rotateZ(-90deg)' // >
          })
        }
      })
    }
  }
}

export default onRedocLoaded
