<template>
  <div id="redoc" ref="redocRef"></div>
</template>

<script>
import {
  ref,
  reactive,
  watch,
  toRaw,
} from 'vue'

import * as Redoc from 'redoc/bundles/redoc.standalone'

import { isEqual, cloneDeep } from 'lodash'

import onRedocLoaded from './utils/onRedocLoaded'

export default {
  name: 'RedocWrapper',
  props: {
    specOrSpecUrl: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
    options: {
      type: Object,
      default() {
        return {}
      },
    },
    scrollTop: Number,
  },
  emits: ['changeIsRedocLoaded'],
  setup(props, { emit }) {
    const redocRef = ref()
    const specOrSpecUrl = reactive(props.specOrSpecUrl)

    const redocOption = {
      hideDownloadButton: true,
      hideHostname: true,
      hideSchemaPattern: true,
      expandResponses: '200',
      showRightPanelToggle: true,
      theme: {
        colors: {
          primary: {
            main: '#947364',
          },
        },
        logo: {
          gutter: '20px 40px',
        },
        typography: {
          code: {
            fontFamily: 'Menlo, Monaco, Consolas, "Courier New", monospace',
            wrap: true,
          },
          headings: {
            fontFamily: 'Open Sans, Lato, Roboto, sans-serif',
          },
        },
        rightPanel: {
          width: '35%',
          backgroundColor: '#303030',
        },
        sidebar: {
          width: '305px',
        },
      },
    }

    const generateSnippetsAndFormat = (spec) => {
      // Generate code snippets of requests
      const langs = [
        'csharp_restsharp',
        'go_native',
        'java_okhttp',
        'java_unirest',
        'javascript_fetch',
        'javascript_jquery',
        'javascript_xhr',
        'c_libcurl',
        'node_axios',
        'node_fetch',
        'node_native',
        'node_request',
        'node_unirest',
        'objc_nsurlsession',
        'ocaml_cohttp',
        'php_curl',
        'php_http1',
        'php_http2',
        'python_python3',
        'python_requests',
        'ruby_native',
        'shell_curl',
        'shell_httpie',
        'shell_wget',
        'swift_nsurlsession',
      ]

      Object.keys((spec.paths || {})).forEach((path) => {
        Object.keys(spec.paths[path]).forEach((method) => {
          const generatedCode = window.OpenAPISnippets.getEndpointSnippets(spec, path, method, langs)

          spec.paths[path][method]['x-codeSamples'] = []
          generatedCode.snippets.forEach(({ title, content }, snippetIdx) => {
            spec.paths[path][method]['x-codeSamples'][snippetIdx] = { lang: title, source: content }
          })

          const { content } = spec.paths[path][method].requestBody
          if (content['application/x-www-form-urlencoded']) {
            content['application/json'] = content['application/x-www-form-urlencoded']
          }
          delete content['application/x-www-form-urlencoded']
        })
      })

      // Insert logo
      if (spec.info) {
        spec.info['x-logo'] = {
          // eslint-disable-next-line global-require
          url: require('@/assets/awc_logo.png'),
          altText: 'AWC logo',
          href: window.location.pathname,
        }
      }

      return spec
    }

    const initRedoc = () => {
      if (toRaw(specOrSpecUrl) && Object.keys(specOrSpecUrl).length) {
        // It will have side effect for specOrSpecUrl if just passing specOrSpecUrl without cloneDeep
        const spec = generateSnippetsAndFormat(cloneDeep(specOrSpecUrl))

        Redoc.init(
          spec,
          { ...redocOption, ...props.options },
          redocRef.value,
          () => {
            onRedocLoaded(spec, props.onRedocLoad, props.scrollTop)
            emit('changeIsRedocLoaded', true)
          },
        )
      }
    }

    watch(() => cloneDeep(specOrSpecUrl), (newVal, oldVal) => {
      // newVal, oldVal will be equal to toRaw(newVal), toRaw(oldVal)
      if (!isEqual(newVal, oldVal)) {
        Object.assign(specOrSpecUrl, newVal)
        initRedoc()
      }
    })

    watch(specOrSpecUrl, newVal => Object.assign(specOrSpecUrl, newVal))

    return {
      redocRef,
    }
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Lato&family=Roboto');

#redoc pre {
  padding-bottom: 16px!important;
  overflow: hidden!important;
  word-wrap: break-word;
}

#redoc  .redoc-wrap {
  background-color: #ffffff;
}

/* menu arrow svg */
#redoc [role=menuitem] > svg {
  flex-shrink: 0;
}

#redoc .post {
  width: 7ex!important;
}

@media screen and (max-width: 50rem) {
  #redoc .menu-open {
    display: flex!important;
  }
  #redoc .menu-close {
    display: none!important;
  }
}

#redoc .menu-active {
  background-color: rgb(237, 237, 237);
}

#redoc .submenu-active {
  background-color: rgb(225, 225, 225);
}

#redoc .menu-inactive {
  color: inherit!important;
  background-color: inherit!important;
}

#redoc .tag-h2 {
  margin-top: 120px;
}

#redoc #fixed {
  position: fixed;
  top: 0;
  right: 0;
  padding: 5px 15px;
  background-color: #eee;
  font-size: 14px;
  z-index: 99;
}

#redoc .min-width-250 {
  min-width: 250px;
}

#redoc .min-width-300 {
  min-width: 300px;
}

#redoc .nowrap {
  white-space: nowrap;
}
</style>
